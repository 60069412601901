export default class AuthService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    selecionarUsuario(id_user) {
        const data = {
            id_user: id_user
        };
        var response = this._http.patch('auth/selecionar-usuario/', data);
        return response;
    }
}
