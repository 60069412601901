<template>
    <v-app id="inspire">
        <app-navigation v-if="$route.path !== '/login'" />
        <v-main id="principal" height="100%" width="100px">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation';
import Vue from 'vue';
import VueNumber from 'vue-number-animation';

Vue.use(VueNumber);

export default {
    name: 'App',
    components: {
        AppNavigation
    },
    data: () => ({
        //
    }),
    mounted() {
        //
    }
};
</script>
