export function formatData(date) {
    if (!date) return null;
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
}

export function formatHour(date) {
    if (!date) return null;
    const hour = date.split('-')[0];
    return hour;
}

export function formatArquivoStatus(status) {
    var statusList = {
        I: 'IMPORTANDO',
        E: 'ERRO',
        P: 'PROCESSADO',
        C: 'CONFIRMADO',
        D: 'CANCELADO',
        F: 'FINALIZADO'
    };

    return statusList[status];
}

export function parseDate(date) {
    if (!date) return null;
    if (!date.includes('/')) return date;
    const [day, month, year] = date.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export function formatDocumento(nu_doc, tp_doc) {
    if (nu_doc) {
        nu_doc = nu_doc.replace(/\D/g, '');
        if (tp_doc === '1') {
            // cnpj
            return nu_doc
                .replace(/^(\d{2})(\d)/, '$1.$2')
                .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
                .replace(/\.(\d{3})(\d)/, '.$1/$2')
                .replace(/(\d{4})(\d)/, '$1-$2');
        } else if (tp_doc === '2') {
            // cpf
            return nu_doc
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        }
    }
    return nu_doc;
}

export function getDescSituacaoTitulo(codigo, situacoes) {
    const value = situacoes.find(situacao => situacao.cd_status == codigo);
    return value ? value.nm_status : `Situação: ${codigo} não encontrada!`;
}

export function formatCurrency(valor) {
    var formatter = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        maximumFractionDigits: 2
    });
    return formatter.format(valor);
}

export function formatDinheiro(valor) {
    if (!valor) return null;
    valor = toFloat(valor);
    return formatCurrency(valor);
}

export function formatPorcentagem(valor) {
    if (!valor) return '';
    valor = toFloat(valor);
    return valor;
}

export function formatCEP(cep) {
    if (cep) {
        return cep.replace(/(\d{5})(\d)/, '$1-$2');
    }
}

export function toFloat(valor) {
    return parseFloat(String(valor).replace(',', '.'));
}

export function toString(valor) {
    if (!valor) return '';
    return String(valor).replace('.', ',');
}

export function formatDateTime(date, hour) {
    //const newDate = date.split('/')
    if (!hour) {
        hour = '00:00:00';
    }
    //const newDateTime = new Date(`${newDate[2]}-${newDate[1]}-${newDate[0]}T${hour}Z`)
    const newDateTime = `${date}T${hour}:00`;
    return newDateTime;
}
