import axios from 'axios';
//import Vue from 'vue';

export default class UsuarioService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    listarUsuarios() {
        return this._http.get('usuario', {
            params: { tp_perfil: 'M' }
        });
    }

    salvarUsuario(usuario) {
        const data = {
            data: usuario,
            cd_apresentante: this._store.getters.cdApresentante
        };
        var response = null;
        if (usuario.id_user) {
            delete data.data['passwordConfirmation'];
            response = this._http.put(`usuario/${usuario.id_user}`, data);
        } else {
            response = this._http.post('usuario/', data);
        }
        return response;
    }

    editarUsuario(usuario) {
        const tp_perfil = this.getTipoPerfil(usuario);
        return this._http.get(`usuario/${usuario.id_user}`, {
            params: {
                tp_perfil: tp_perfil,
                cd_apresentante: this._store.getters.cdApresentante
            }
        });
    }

    getHistoricoUsuario(usuario) {
        return this._http.get(`usuario/${usuario.id_user}/historico`, {
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                id_user: usuario.id_user
            }
        });
    }

    getTipoPerfil(usuario) {
        if (usuario.tp_perfil === 'PAINEL') {
            return 'M';
        }
        if (usuario.tp_perfil === 'API') {
            return 'A';
        }
    }

    async buscarRelatoriosSolicitados() {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'usuario/relatorios',
                {
                    params: {
                        id_user: this._store.getters.idUser
                    },
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }
}
