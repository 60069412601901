<template>
    <div class="ma-3">
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text pa-2"> mdi-domain </v-icon>Inicio >
                Sobre nós
            </v-card-text>
        </v-card>
        <v-card :color="primaryColorFont" height="100vh">
            <v-row align="center" justify="center">
                <v-col class="text-center ma-3 pa-3" md="12" lg="10">
                    <v-card-text
                        class="display-2 white--text font-weight-black"
                    >
                        Somos a Recuperi, sua parceira na recuperação de
                        valores!
                    </v-card-text>
                </v-col>
                <v-col class="" md="12" lg="12">
                    <v-card-text
                        class="display-4 white--text font-weight-black text-center"
                        >+ de 2.2 bilhões
                    </v-card-text>
                </v-col>
                <v-col class="" md="12" lg="8">
                    <v-card-text class="white--text font-weight-normal">
                        Nossos clientes já contam com mais de 2.2 bilhões de
                        reais em dívidas protestadas/recuperadas no último ano
                        (entre duplicatas, cédulas bancárias e outros títulos em
                        atraso).
                    </v-card-text>
                </v-col>
            </v-row>
            <v-col sm="12" md="11" class="mx-auto">
                <v-card class="ma-5 Pa-5">
                    <v-img
                        src="@/assets/banner-1-site.png"
                        full-width
                        class="mx-auto my-auto"
                    />
                </v-card>
            </v-col>
            <v-row>
                <v-col class="text-left ma-auto" md="12" lg="10" cols="10">
                    <v-card-text
                        class="display-2 primary--text font-weight-black"
                    >
                        <v-icon class="primary--text pa-2"> mdi-domain </v-icon
                        >Sobre nós
                    </v-card-text>
                    <v-card-text>
                        A Recuperi é uma startup paulistana fundada em 2017, com
                        atuação em todo o território brasileiro. Desenvolvemos
                        nossas Plataformas com tecnologia própria e um modelo de
                        negócios inovador, para ajudar você desde a emissão de
                        boletos até a cobrança e negociação de valores em
                        atraso. Queremos ser a sua parceira na recuperação de
                        valores, trazendo uma estrutura única, com maior
                        controle gerencial e menos custos.
                    </v-card-text>
                </v-col>
                <v-col class="text-left ma-auto" md="12" lg="10" cols="10">
                    <v-card-text
                        class="display-2 primary--text font-weight-black"
                    >
                        <v-icon class="primary--text"> mdi-finance </v-icon>
                        Já somos 2.2 bilhões!
                    </v-card-text>
                    <v-card-text>
                        Nossos clientes já contam com mais de 2.2 bilhões de
                        reais em dívidas protestadas/recuperadas no último ano
                        (entre duplicatas, cédulas bancárias e outros títulos em
                        atraso).
                    </v-card-text>
                </v-col>
            </v-row>
            <v-col class="ma-auto" md="10" lg="12">
                <v-row>
                    <v-col class="text-center" md="12" lg="1" cols="12">
                    </v-col>
                    <v-col class="text-center" md="12" lg="2">
                        <v-card rounded class="primary" min-height="270px">
                            <v-icon class="white--text my-5" size="100">
                                mdi-format-list-checks
                            </v-icon>

                            <v-card-text
                                class="title white--text font-weight-black"
                            >
                                Emissão de Protestos
                            </v-card-text>
                        </v-card>
                        <v-card-text class="text-left">
                            Protestos automáticos enviados diretamente aos
                            cartórios através de conexão de dados
                        </v-card-text>
                    </v-col>
                    <v-col class="text-center" md="12" lg="2">
                        <v-card rounded class="primary" min-height="270px">
                            <v-icon class="white--text my-5" size="100">
                                mdi-cash-check
                            </v-icon>
                            <v-card-text
                                class="title white--text font-weight-black"
                            >
                                Recuperação de títulos
                            </v-card-text>
                        </v-card>
                        <v-card-text class="text-left">
                            Fazemos a recuperação após o upload dos títulos no
                            sistema com ótima taxa de retorno e recuperação.
                        </v-card-text>
                    </v-col>
                    <v-col class="text-center" md="12" lg="2">
                        <v-card rounded class="primary" min-height="270px">
                            <v-icon class="white--text my-5" size="100">
                                mdi-cellphone-message
                            </v-icon>
                            <v-card-text
                                class="title white--text font-weight-black"
                            >
                                Envio de mensagens SMS
                            </v-card-text>
                        </v-card>
                        <v-card-text class="text-left">
                            Envios automáticos de SMS pré configurados pelo
                            usuário (com pequeno custo adicional por envio)
                        </v-card-text>
                    </v-col>
                    <v-col class="text-center" md="12" lg="2">
                        <v-card rounded class="primary" min-height="270px">
                            <v-icon class="white--text my-5" size="100">
                                mdi-email
                            </v-icon>
                            <v-card-text
                                class="title white--text font-weight-black"
                            >
                                Envio de Emails automáticos
                            </v-card-text>
                        </v-card>
                        <v-card-text class="text-left">
                            Envios automáticos de emails pré configurados pelo
                            usuário (com pequeno custo adicional por envio)
                        </v-card-text>
                    </v-col>
                    <v-col class="text-center" md="12" lg="2" cols="12">
                        <v-card class="primary" min-height="270px">
                            <v-icon class="white--text my-5" size="100">
                                mdi-chart-arc
                            </v-icon>
                            <v-card-text
                                class="title white--text font-weight-black"
                            >
                                Relatórios Detalhados
                            </v-card-text>
                        </v-card>
                        <v-card-text class="text-left">
                            Tenha em mãos relatórios completos da aplicação,
                            desde número de uploads, recuperados, em recuperação
                            e muito mais!
                        </v-card-text>
                    </v-col>
                    <v-col class="text-center" md="12" lg="1"> </v-col>
                </v-row>
            </v-col>
            <v-col></v-col>
        </v-card>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    components: {},
    data() {
        return {
            primaryColorFont: '#05c3dd'
        };
    },
    methods: {
        theFormat(number) {
            return number.toFixed(0);
        },
        completed() {},
        playAnimation() {
            this.$refs.number2.play();
        },
        mounted() {
            if (this.$store.getters.isPartner == 1) {
                this.primaryColorFont = '#05c3dd';
            } else {
                this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
            }
        }
    }
});
</script>
