var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showModal
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.showModal,
                callback: function($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "social-user-modal-alterar-usuario" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        "v-toolbar-title",
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v(" mdi-swap-horizontal ")
                          ]),
                          _vm._v(" Alterar Empresa ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.fecharAlterarUsuario }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("SocialUserAlterarUsuario", {
                        attrs: { reload: true }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }