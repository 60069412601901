<template>
    <div>
        <v-dialog
            v-if="showModal"
            v-model="showModal"
            max-width="600px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre o Upload -->
            <v-card class="social-user-modal-alterar-usuario">
                <v-toolbar flat dark color="primary">
                    <v-toolbar-title>
                        <v-icon color="white">
                            mdi-swap-horizontal
                        </v-icon>
                        Alterar Empresa
                    </v-toolbar-title>

                    <v-btn icon dark @click="fecharAlterarUsuario">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <SocialUserAlterarUsuario :reload="true" />
                </v-card-text>
            </v-card>
            <!-- fim das informações sobre o Upload -->
        </v-dialog>
    </div>
</template>

<script>
import SocialUserAlterarUsuario from './SocialUserAlterarUsuario.vue';

export default {
    name: 'SocialUserModalAlterarUsuario',
    components: {
        SocialUserAlterarUsuario
    },
    props: {
        showModal: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({}),
    computed: {},
    methods: {
        fecharAlterarUsuario() {
            this.$emit('fechar');
        }
    },
    beforeDestroy() {},

    mounted() {}
};
</script>

<style lang="scss">
.social-user-modal-alterar-usuario {
    .v-toolbar {
        .v-toolbar__content {
            display: flex;
            justify-content: space-between !important;
        }
    }
}
</style>
