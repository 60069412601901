<template>
    <div class="social-user-alterar-usuario-component">
        <strong v-if="socialUsers && socialUsers.length > 0">Selecione o acesso para prosseguir:</strong>
        <table v-if="socialUsers && socialUsers.length > 0">
            <thead>
            <tr>
                <th>Empresa</th>
                <th>CNPJ</th>
                <th>Admin</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="user in socialUsers" :key="user.id">
                <td>
                {{
                    user.nome_exibicao
                    ? user.nome_exibicao
                    : user.nm_razao_social
                }}
                ({{ user.cd_apresentante }})
                </td>
                <td>{{ use_mask(user.nu_cnpj, '##.###.###/####-##') }}</td>
                <td>{{ user.is_admin ? 'Sim' : 'Nao' }}</td>
                <td>
                <button @click="selecionarUsuarioAcesso(user)" :disabled="loadingUserId === user.id" class="access-button">
                    <span v-if="loadingUserId === user.id" class="loading-spinner"></span>
                    <span v-else>Acessar</span>
                </button>
                </td>
            </tr>
            </tbody>
        </table>
        <p v-else style="font-weight: bold; text-align: center;">
            Seu usuário não está habilitado para acessar a recuperi, entre em contato com o susporte
        </p>
    </div>
</template>

<script>
import Vue from 'vue';
import { mask } from 'maska';
import AuthService from '@/services/AuthService';

export default {
    name: 'SocialUserAlterarUsuario',
    components: {},
    props: {
        reload: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        showModal: false,
        loadingUserId: null
    }),
    computed: {
        userProfile() {
            return this.$store.getters.user;
        },

        socialUsers() {
            try {
                return this.$store.state.user.users;
            } catch (e) {
                return [];
            }
        }
    },
    methods: {
        selecionarUsuarioAcesso(user) {
            this.loadingUserId = user.id;
            let service = new AuthService(Vue.http, this.$store);

            service.selecionarUsuario(user.id_user).then(
                response => {
                    let user = this.$store.getters.user;
                    let resp_user = response.body.body;

                    user[
                        'ds_identidade_visual'
                    ] = resp_user.ds_identidade_visual
                        ? JSON.parse(
                              resp_user.ds_identidade_visual.replace(/'/g, '"')
                          )
                        : null;
                    user['cra_nacional'] = resp_user.cra_nacional;
                    user['ds_agrupador'] = resp_user.ds_agrupador;
                    user['link_contato'] = resp_user.link_contato;
                    user['nome_exibicao'] = resp_user.nome_exibicao;
                    user['nm_apresentante'] = resp_user.nm_apresentante;
                    user['cd_apresentante'] = resp_user.cd_apresentante;
                    user['id_user'] = resp_user.id_user;
                    user['name'] = resp_user.nm_user;
                    user['email'] = resp_user.ds_email;
                    user['login'] = resp_user.ds_login;
                    user['status'] =
                        resp_user.st_ativo === 1 ? 'ATIVO' : 'INATIVO';
                    user['perfil'] = resp_user.tp_perfil;
                    user['dataCadastro'] = resp_user.dt_cadastro;
                    user['is_superuser'] = resp_user.is_superuser;
                    user['is_admin'] = resp_user.is_admin;
                    user['is_lite'] = resp_user.is_lite;
                    user['password_expire_in'] = resp_user.password_expire_in;
                    user['nu_cnpj'] = resp_user.nu_cnpj;
                    this.$store.commit('setUser', user);
                    this.$store.commit('setToken', response.body.access_token);
                    this.$store.commit('setCnpj', resp_user.nu_cnpj);
                    this.$router.push('/consulta-titulos');
                    if (this.reload) {
                        window.location.reload();
                    }
                    this.loadingUserId = null;
                },
                error => {
                    console.log(error);
                    this.loadingUserId = null;
                }
            );
        },

        use_mask(value, mask_str) {
            return mask(value, mask_str);
        }
    },
    beforeDestroy() {},

    mounted() {}
};
</script>

<style scoped lang="scss">
.social-user-alterar-usuario-component {
    margin: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;

    strong {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 10px;
    }

    table {
        border: 1px solid #ccc;
        width: 100%;
        tbody {
            td {
                text-align: center;

                button {
                    background-color: #26c6da;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 4px;
                }
            }
        }
    }
}

.access-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 30px;
    position: relative;
}

.loading-spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 5px;
}

.access-button span {
    visibility: visible;
}

.access-button:disabled span {
    visibility: hidden;
}

.access-button:disabled .loading-spinner {
    visibility: visible;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
