var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "social-user-alterar-usuario-component" }, [
    _c("table", [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.socialUsers, function(user) {
          return _c("tr", { key: user.id }, [
            _c("td", [
              _vm._v(
                " " +
                  _vm._s(
                    user.nome_exibicao
                      ? user.nome_exibicao
                      : user.nm_razao_social
                  ) +
                  " (" +
                  _vm._s(user.cd_apresentante) +
                  ") "
              )
            ]),
            _c("td", [
              _vm._v(_vm._s(_vm.use_mask(user.nu_cnpj, "##.###.###/####-##")))
            ]),
            _c("td", [_vm._v(_vm._s(user.is_admin ? "Sim" : "Nao"))]),
            _c("td", [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.selecionarUsuarioAcesso(user)
                    }
                  }
                },
                [_vm._v(" Acessar ")]
              )
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Empresa")]),
        _c("th", [_vm._v("CNPJ")]),
        _c("th", [_vm._v("Admin")]),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }