var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "social-user-alterar-usuario-component" }, [
    _vm.socialUsers && _vm.socialUsers.length > 0
      ? _c("strong", [_vm._v("Selecione o acesso para prosseguir:")])
      : _vm._e(),
    _vm.socialUsers && _vm.socialUsers.length > 0
      ? _c("table", [
          _vm._m(0),
          _c(
            "tbody",
            _vm._l(_vm.socialUsers, function(user) {
              return _c("tr", { key: user.id }, [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(
                        user.nome_exibicao
                          ? user.nome_exibicao
                          : user.nm_razao_social
                      ) +
                      " (" +
                      _vm._s(user.cd_apresentante) +
                      ") "
                  )
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.use_mask(user.nu_cnpj, "##.###.###/####-##"))
                  )
                ]),
                _c("td", [_vm._v(_vm._s(user.is_admin ? "Sim" : "Nao"))]),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "access-button",
                      attrs: { disabled: _vm.loadingUserId === user.id },
                      on: {
                        click: function($event) {
                          return _vm.selecionarUsuarioAcesso(user)
                        }
                      }
                    },
                    [
                      _vm.loadingUserId === user.id
                        ? _c("span", { staticClass: "loading-spinner" })
                        : _c("span", [_vm._v("Acessar")])
                    ]
                  )
                ])
              ])
            }),
            0
          )
        ])
      : _c(
          "p",
          { staticStyle: { "font-weight": "bold", "text-align": "center" } },
          [
            _vm._v(
              " Seu usuário não está habilitado para acessar a recuperi, entre em contato com o susporte "
            )
          ]
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Empresa")]),
        _c("th", [_vm._v("CNPJ")]),
        _c("th", [_vm._v("Admin")]),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }