/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

import * as Formatter from '@/helpers/Formatter';

const GlobalDirectives = {
    install(Vue) {
        Vue.mixin({
            data: function() {
                return {};
            },
            computed: {},
            created() {},
            mounted() {},
            methods: {
                getDescSituacao(titulo) {
                    return Formatter.getDescSituacaoTitulo(
                        titulo.cd_status,
                        this.$store.getters.todasSituacoesTitulo
                    );
                }
            }
        });
    }
};

export default GlobalDirectives;
