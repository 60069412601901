import axios from 'axios';

export default class ComarcaService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
        this._ctrl = store.getters.getAbortCtrl;
    }

    buscarEnvios(filtros) {
        return this._http.get('emails/relatorios/envios', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                ds_nosso_numero: filtros.ds_numero_titulo,
                nm_devedor: filtros.nm_devedor,
                cd_status: filtros.cd_status,
                dt_inicio: filtros.dt_inicio,
                dt_fim: filtros.dt_fim,
                ds_email_devedor: filtros.email_devedor
            }
        });
    }

    buscarEnviosPorDetalheTitulo(titulo) {
        return this._http.get('emails/relatorios/envios', {
            params: {
                obter_conteudo_enviado: true,
                cd_apresentante: titulo.cd_apresentante,
                ds_nosso_numero: titulo.ds_nosso_numero,
                per_page: 1000,
                page: 1,
                completa: 1
            }
        });
    }

    buscarEnviosPaginados(filtros, page, itemsPerPage, dataInicial, dataFinal) {
        return this._http.get('emails/relatorios/envios', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                ds_nosso_numero: filtros.ds_nosso_numero,
                nm_devedor: filtros.nm_devedor,
                cd_status: filtros.cd_status,
                dt_inicio: dataInicial,
                dt_fim: dataFinal,
                ds_email_devedor: filtros.ds_email_devedor,
                page: page,
                per_page: itemsPerPage,
                completa: 1
            }
        });
    }

    exportarXlsEmail(filtros, emails) {
        return this._http.post(
            'exportar_titulos_sqs',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                filtros,
                emails,
                modelo_relatorio: 'xls_emails_transacionais'
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }

    buscarEnviosPeriodo(graficos) {
        return axios.get(
            process.env.VUE_APP_RECUPERI_API + 'emails/relatorios/envios',
            {
                params: {
                    cd_apresentante: this._store.getters.cdApresentante,
                    dt_inicio: graficos.dt_inicio,
                    dt_fim: graficos.dt_fim,
                    completa: 0
                },
                headers: {
                    Authorization: 'Bearer ' + this._store.getters.token
                },
                signal: this._ctrl.signal
            }
        );
    }

    buscarEnviosConsolidado() {
        return axios.get(
            process.env.VUE_APP_RECUPERI_API +
                `emails/relatorio_apresentante/${this._store.getters.cdApresentante}`,
            {
                headers: {
                    Authorization: 'Bearer ' + this._store.getters.token
                },
                signal: this._ctrl.signal
            }
        );
    }
}
